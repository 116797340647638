import { Layout } from "antd";
import { Footer } from "../modules/Footer";
import { NewUpdatesDrawer } from "../modules/NewUpdatesDrawer";
import { ShareFeedbackDrawer } from "../modules/ShareFeedbackDrawer";
import { BDONavbar } from "../modules/BDONavbar";
import { useDrawerContext } from "../hooks/useDrawerContext";

import styles from "./MainLayout.module.css";
import { SettingsDrawer } from "../modules/SettingsDrawer";

const { Content } = Layout;
export const MainLayout = ({ children, clearChat }) => {
  const { isTabletView, isMobileView } = useDrawerContext();
  return (
    <div className={styles.mainLayout}>
      <BDONavbar clearChat={clearChat} />
      <Content className={styles.layoutContent}>{children}</Content>
      {!isTabletView && !isMobileView ? <Footer /> : null}
      <ShareFeedbackDrawer />
      <NewUpdatesDrawer />
      <SettingsDrawer />
    </div>
  );
};
