import { Popover } from "antd";
import styles from "./UploadFilePopover.module.css";

const Content = () => {
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.mainHeading}>Upload a file</h1>
      <p className={styles.textStyles}>
        Upload file(s) (<strong>Max: 3</strong>) and chat with them.
        <br /> This action will start a new thread.
      </p>
    </div>
  );
};

export const UploadFilePopover = ({
  children,
  title,
  uploadProps,
  showFileUploadButton,
}) => {
  return (
    <Popover
      placement="topLeft"
      title={title}
      content={
        showFileUploadButton ? <Content uploadProps={uploadProps} /> : null
      }
    >
      {children}
    </Popover>
  );
};
