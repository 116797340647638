import {
  SaveOutlined,
  UploadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Select, Button, Tooltip, Modal, Layout, Drawer } from "antd";
import classNames from "classnames";

import { useDrawerContext } from "../../hooks/useDrawerContext";

import styles from "./PersonaSelectionDrawer.module.css";

const PersonaSelectionWrapper = ({ children }) => {
  const {
    rightsideCollapseStatus,
    setRightsideCollapseStatus,
    isMobileView,
    isTabletView,
  } = useDrawerContext();
  const { Sider } = Layout;
  if (isMobileView || isTabletView) {
    return (
      <Drawer
        title="Persona selection"
        placement="right"
        onClose={() => setRightsideCollapseStatus(true)}
        width={isMobileView ? "100%" : 300}
        open={!rightsideCollapseStatus}
        className={styles.personaSelection}
      >
        {children}
      </Drawer>
    );
  }
  return (
    <Sider
      collapsed={rightsideCollapseStatus}
      width={300}
      trigger={null}
      collapsedWidth="0"
    >
      {children}
    </Sider>
  );
};

export const PersonaSelectionDrawer = ({
  onPersonasChange,
  listPersonas,
  selectedPersonaRef,
  personaText,
  saveUserPersona,
  setPersonaText,
  handleUpdatePersona,
  deleteUserPersona,
  customPersonaExist,
  handleSavePersona,
  showSavePersonaModal,
  handleCancel,
  personaName,
  setPersonaName,
  showDeletePersonaModal,
  handleDeletePersona,
}) => {
  const { isMobileView, isTabletView } = useDrawerContext();
  return (
    <PersonaSelectionWrapper>
      <section
        className="bdo-right-sidebar-content"
        id="rightsidebar"
        style={{ overflow: "hidden" }}
      >
        {!isMobileView && !isTabletView ? (
          <div className={styles.personaHeaderWrapper}>
            <h3 className={styles.personaSelectionHeading}>
              Persona selection
            </h3>
            <Tooltip
              placement="bottom"
              title="Select the BDO persona that you would like to use with your prompt."
            >
              <InfoCircleOutlined className={styles.personaHeaderInfoIcon} />
            </Tooltip>
          </div>
        ) : null}
        <div className="flex-row">
          <Select
            placeholder="Change BDO persona"
            className={styles.personas}
            onChange={onPersonasChange}
            suffixIcon={<DownOutlined className={styles.dropdownIcon} />}
            value={null}
          >
            {listPersonas}
          </Select>
        </div>
        <div className={classNames("bdo-card", styles.bdoPersonaCard)}>
          <header className="bdo-card-header">
            <img
              className={styles.personaImage}
              src="persona_logo.svg"
              alt="persona_logo"
            />
            <section>
              <h4>
                <span className={styles.personalDetailsheading}>
                  Persona details
                </span>
              </h4>
              <div className={styles.bdoPersonaTypeName}>
                {selectedPersonaRef.current != null
                  ? selectedPersonaRef.current.name
                  : ""}
              </div>
            </section>
          </header>
          <textarea
            value={personaText}
            className={classNames("noResize", styles.textAreaInputStyles)}
            onChange={(e) => setPersonaText(e.target.value)}
            autosize={{ minRows: 1, maxRows: 6 }}
            id="personas"
            placeholder="Create a new Persona"
          />
          <div className="flex-row justify-spaced persona-actions">
            <div className="flex-row" style={{ gap: "12px" }}>
              <div
                className={classNames("icon-button", styles.saveAsStyles)}
                onClick={saveUserPersona}
              >
                <SaveOutlined />
                <label className={styles.saveAsText}>Save as</label>
              </div>
              {selectedPersonaRef.current &&
                selectedPersonaRef.current.isCustom && (
                  <div
                    className={classNames("icon-button", styles.saveAsStyles)}
                    onClick={handleUpdatePersona}
                  >
                    <UploadOutlined />
                    <label>Update</label>
                  </div>
                )}
            </div>
            {selectedPersonaRef.current &&
              selectedPersonaRef.current.isCustom && (
                <div
                  className="icon-button destructive"
                  onClick={deleteUserPersona}
                >
                  <DeleteOutlined />
                  <label>Delete</label>
                </div>
              )}
          </div>
        </div>

        <Modal
          title="Save as custom persona"
          open={showSavePersonaModal}
          onCancel={handleCancel}
          closable={true}
          destroyOnClose={true}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleSavePersona}
              disabled={!personaName.length}
            >
              Save
            </Button>,
          ]}
        >
          <label>Persona Name:</label>
          <input
            type="text"
            className={styles.personalNameInputStyles}
            onChange={(e) => setPersonaName(e.target.value)}
          ></input>
          {customPersonaExist.length ? (
            <label className="custom-persona-exists">
              {customPersonaExist}
            </label>
          ) : null}
        </Modal>
        <Modal
          title="Delete Persona Confirmation"
          open={showDeletePersonaModal}
          onOk={handleDeletePersona}
          okText="Ok"
          cancelText="Cancel"
          onCancel={handleCancel}
          closable={true}
        >
          <p>Are you sure you want to delete this persona?</p>
        </Modal>
      </section>
    </PersonaSelectionWrapper>
  );
};
