export const RightSidebarIcon = () => {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="2 2 16 16"
        fill="currentColor"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.515 4.901h2.584V16.1h-2.584V4.9ZM12.223 3.61h5.168v13.782H3.609V3.609h8.614Zm0 1.292V16.1H4.9V4.9h7.322Z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};
