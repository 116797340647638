import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import styles from "./BDOVoiceMessage.module.css";
import { useGetPDF } from "../../hooks/useGetPDF";
import { routes } from "../../config/apiRoutes";

export function BdoVoiceMessage() {
  const { getData: getStyleGuideData } = useGetPDF({
    endpoint: routes.getStyleGuide,
    onSuccess: (response) => {
      downloadPdf(response);
    },
  });
  const handleBrandingGuidelinesClick = async () => {
    getStyleGuideData();
  };
  const downloadPdf = async (data) => {
    try {
      const pdfBlob = await data.blob();
      const url = window.URL.createObjectURL(pdfBlob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "WritingStyleGuide.pdf";
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <Tooltip
      placement="bottomLeft"
      overlayClassName={styles.tooltipStyles}
      title={
        <>
          <p>
            Activating <strong>BDO Voice</strong> applies our brand in terms of
            language and writing style to the AI-generated output.
          </p>
          <p>
            {" "}
            For more information on <strong>BDO Voice</strong> please refer to
            our
            <a onClick={handleBrandingGuidelinesClick}> brand guidelines</a> on
            Insite
          </p>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
}
