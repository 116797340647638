export const NewChatIcon = () => {
  return (
    <span className="anticon">
      <svg
        viewBox="0 0 16 16"
        focusable="false"
        data-icon="new-chat"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M8 1H1v14h14V8h-1.5v5.5h-11v-11H8V1Z" />
        <path d="M5.343 11.25c.035 0 .07-.004.106-.009l2.956-.518a.173.173 0 0 0 .093-.05l7.452-7.45a.175.175 0 0 0 0-.249L13.028.051A.174.174 0 0 0 12.903 0a.174.174 0 0 0-.124.051L5.327 7.502a.179.179 0 0 0-.049.094l-.519 2.956a.589.589 0 0 0 .166.524.595.595 0 0 0 .418.174Zm1.185-3.066 6.375-6.373 1.289 1.288-6.376 6.374-1.562.276.274-1.565Z" />
      </svg>
    </span>
  );
};
