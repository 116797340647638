import classNames from "classnames";

import styles from "./chatAILoader.module.css";
import { AnimatedElipse } from "../../Icons";
import { SparklesLoader } from "../../Icons/SparklesLoader";
import { useDrawerContext } from "../../hooks/useDrawerContext";

export const ChatAILoader = ({ isLoading }) => {
  const { showEvidencesDrawer } = useDrawerContext();
  if (!isLoading) return null;
  return (
    <div
      className={classNames(
        "chat-message-center flex-row",
        styles.newMessageLoader
      )}
      style={{ padding: showEvidencesDrawer ? "2rem 1rem" : "2rem 5rem" }}
    >
      <div className={classNames("chat-message", styles.iconLoaderWrapper)}>
        <div className={styles.avatarWrapper}>
          <SparklesLoader />
        </div>
        <AnimatedElipse className={styles.animatedElipse} />
      </div>
    </div>
  );
};
