import { Tag } from "antd";

import styles from "./UploadedFilesViewTags.module.css";

export const UploadedFilesViewTags = ({
  handleClose,
  files,
  handleFileRemoveChange,
}) => {
  return (
    <div className={styles.tagWrapper}>
      {files?.map((file, index) => (
        <span
          key={file}
          style={{
            display: "inline-block",
          }}
        >
          <Tag
            className={styles.tagStyles}
            closable
            onClose={(e) => {
              e.preventDefault();
              handleFileRemoveChange(file, index);
            }}
          >
            <span className={styles.ellipsisFileName}>{file.name}</span>
          </Tag>
        </span>
      ))}
    </div>
  );
};
