import { Drawer, Switch, notification } from "antd";
import { useMsal } from "@azure/msal-react";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { routes } from "../../config/apiRoutes";
import { useMutate } from "../../hooks/useMutate";
import { BdoVoiceMessage } from "../../components/BDOVoiceMessage";
import {
  NotificationOutlined,
  MailOutlined,
  UploadOutlined,
  RightOutlined,
} from "@ant-design/icons";
import classNames from "classnames";

import styles from "./settingsDrawer.module.css";

export const SettingsDrawer = () => {
  const [, contextHolder] = notification.useNotification();
  const {
    showSettingsDrawer,
    setShowSettingsDrawer,
    isMobileView,
    isBDOVoice,
    setIsBDOVoice,
    setShowFeedbackDrawer,
    setShowUpdatesDrawer,
  } = useDrawerContext();

  const closeDrawer = () => {
    setShowSettingsDrawer(false);
  };

  const { mutate: clearUserData } = useMutate({
    endpoint: routes.clearUserData,
  });
  const { instance } = useMsal();

  const logOut = () => {
    clearUserData({ body: JSON.stringify({}) });
    localStorage.clear();
    instance.logoutRedirect();
  };

  const handleShareFeedback = () => {
    setShowFeedbackDrawer(true);
  };

  const handleViewUpdates = () => {
    setShowUpdatesDrawer(true);
  };
  return (
    <>
      {contextHolder}
      <Drawer
        title="Settings"
        placement="right"
        onClose={closeDrawer}
        width={isMobileView ? "100%" : 300}
        open={showSettingsDrawer}
        className={styles.settingsDrawer}
      >
        <div className={styles.settingsWrapper}>
          <label className={styles.settingsItemLabel}>BDO Voice</label>
          <div className={styles.settingsOptionWrapper}>
            <div className={styles.bdoVoiceWwrapper}>
              <div className={styles.switchWrapper}>
                <Switch
                  size="small"
                  checked={isBDOVoice}
                  onChange={setIsBDOVoice}
                />
                <p className={styles.activeTextStyles}>Active</p>
              </div>
              <BdoVoiceMessage />
            </div>
          </div>
          <label
            className={classNames(
              styles.settingsItemLabel,
              styles.accountSettingsLable
            )}
          >
            Account
          </label>
          <div
            onClick={handleViewUpdates}
            className={classNames(
              styles.settingsOptionWrapper,
              styles.accountSettingsOptionWrapper
            )}
          >
            <div className={styles.iconTextWrapper}>
              <NotificationOutlined />
              <p>New updates</p>
            </div>
            <RightOutlined />
          </div>
          <div
            onClick={handleShareFeedback}
            className={classNames(
              styles.settingsOptionWrapper,
              styles.accountSettingsOptionWrapper
            )}
          >
            <div className={styles.iconTextWrapper}>
              <MailOutlined />
              <p>Share Feedback</p>
            </div>
            <RightOutlined />
          </div>
          <div
            onClick={logOut}
            className={classNames(
              styles.settingsOptionWrapper,
              styles.accountSettingsOptionWrapper
            )}
          >
            <div className={styles.iconTextWrapper}>
              <UploadOutlined style={{ transform: "rotate(90deg)" }} />
              <p>Sign out</p>
            </div>
            <RightOutlined />
          </div>
        </div>
      </Drawer>
    </>
  );
};
