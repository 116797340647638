import classNames from "classnames";

export const AnimatedElipse = ({ className }) => {
  return (
    <span className={classNames("anticon", className)}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 45 10"
        xmlns="http://www.w3.org/2000/svg"
        fill="#657B90"
      >
        <circle cx="5" cy="5" r="5">
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="0.8s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="20" cy="5" r="5">
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="0.8s"
            begin="0.2s"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="35" cy="5" r="5">
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="0.8s"
            begin="0.4s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </span>
  );
};
