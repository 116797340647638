import { Layout } from "antd";
import styles from "./footer.module.css";

const { Footer: AntdFooter } = Layout;
export const Footer = () => {
  return (
    <AntdFooter className={styles.bdoFooter}>
      <div className={styles.textWrapper}>
        {/* <InfoCircleOutlined className={styles.textInfoIcon} /> */}
        <p className={styles.footerText}>
          Please note that Personas is a preview product, and may change or be
          unavailable at short notice. Visit{" "}
          <a
            className={styles.linkStyles}
            target="_blank"
            rel="noreferrer"
            href="https://bdouk.sharepoint.com/sites/LearningPathways/SitePages/AI%20on%20LearnIT.aspx"
          >
            LearnIT
          </a>{" "}
          for AI resources, find the firm’s latest AI guidance on{" "}
          <a
            className={styles.linkStyles}
            target="_blank"
            rel="noreferrer"
            href="https://insite.bdo.co.uk/sites/IT/aboutit/innovationdigital/ai/Pages/Our-guidance-on-the-use-of-AI.aspx"
          >
            Insite
          </a>
          , and for support you can raise a ticket{" "}
          <a
            className={styles.linkStyles}
            target="_blank"
            rel="noreferrer"
            href="https://insite.bdo.co.uk/sites/knowledge/redirects/Pages/go.aspx?title=itsupporthub"
          >
            here
          </a>
          .
        </p>
      </div>
      <div className={styles.footerLogoWrapper}>
        <img
          className={styles.labsLogo}
          src="black_white_labs_logo.png"
          alt="black_white_labs_logo"
        />
        <img
          className={styles.bdoLogo}
          src="white_bdo_logo.png"
          alt="white_bdo_logo"
        />
      </div>
    </AntdFooter>
  );
};
