import React from "react";

import styles from "./evidenceWindow.module.css";
import { CloseIcon } from "../../Icons";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { Layout, Drawer, Tooltip } from "antd";

const EvidenceWindowWrapper = ({ children }) => {
  const {
    pdfEvidenceUrl: { name },
    showEvidencesDrawer,
    handleSetPdfUrl,
    showEvidenceInSidebar,
    isMobileView,
  } = useDrawerContext();

  const { Sider } = Layout;

  const handleOnClose = () => {
    handleSetPdfUrl({}, false);
  };

  if (!showEvidenceInSidebar) {
    return (
      <Drawer
        title={
          <Tooltip placement="topLeft" title={name}>
            <h2>{name}</h2>
          </Tooltip>
        }
        placement="left"
        onClose={handleOnClose}
        width={isMobileView ? "100%" : "max-content"}
        open={showEvidencesDrawer}
        className={styles.evidenceDrawer}
      >
        {children}
      </Drawer>
    );
  }
  return (
    <Sider
      collapsed={!showEvidencesDrawer}
      width={"max-content"}
      trigger={null}
      collapsedWidth="0"
      className={styles.wrapperStyles}
    >
      <div className={styles.evidenceHeader}>
        <Tooltip placement="topLeft" title={name}>
          <h2>{name}</h2>
        </Tooltip>

        <CloseIcon className={styles.crossIconStyles} onClick={handleOnClose} />
      </div>
      {children}
    </Sider>
  );
};

export function EvidenceWindow() {
  const {
    pdfEvidenceUrl: { url },
    showEvidenceInSidebar,
  } = useDrawerContext();

  return (
    <EvidenceWindowWrapper>
      <div
        className={
          showEvidenceInSidebar
            ? styles.pdfContainerSider
            : styles.pdfContainerDrawer
        }
      >
        {!!url ? (
          <iframe
            className={
              showEvidenceInSidebar
                ? styles.pdfIframeSider
                : styles.pdfIframeDrawer
            }
            title="Pdf Viewer"
            src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}
          ></iframe>
        ) : null}
      </div>
    </EvidenceWindowWrapper>
  );
}
