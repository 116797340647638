import classNames from "classnames";

import styles from "./SparklesLoader.module.css";

export const SparklesLoader = ({ className }) => {
  return (
    <span className={classNames("anticon", className)}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="path1"
          className={styles.pulse1}
          d="M11.6807 10.3493L15.3119 11.4156C15.8747 11.5796 15.8747 12.3096 15.3119 12.4736L11.6807 13.5398L10.5132 16.8561C10.3336 17.3701 9.53426 17.3701 9.35465 16.8561L8.18714 13.5398L4.55589 12.4736C3.99309 12.3096 3.99309 11.5796 4.55589 11.4156L8.18714 10.3493L9.35465 7.03306C9.53426 6.51908 10.3336 6.51908 10.5132 7.03306L11.6807 10.3493Z"
          fill="#E81A3B"
        />
        <path
          id="path2"
          className={styles.pulse2}
          d="M15.013 5.43973L16.5252 5.92582C16.7592 5.99973 16.7592 6.33232 16.5252 6.40623L15.013 6.89232L14.5251 8.39893C14.4509 8.63203 14.1171 8.63203 14.0429 8.39893L13.555 6.89232L12.0428 6.40623C11.8088 6.33232 11.8088 5.99973 12.0428 5.92582L13.555 5.43973L14.0429 3.93312C14.1171 3.70003 14.4509 3.70003 14.5251 3.93312L15.013 5.43973Z"
          fill="#E81A3B"
        />
      </svg>
    </span>
  );
};
