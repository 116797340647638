import { useEffect, useState } from "react";
import { Button, Modal } from 'antd'
import CountdownTimer from "../../../components/popups/logoutCounter";
import { useMsal } from "@azure/msal-react";
import { useMutate } from "../../../hooks/useMutate";
import { routes } from "../../../config/apiRoutes";
import { useIdleTimer } from "react-idle-timer"
import useIdleTimeout from "./AutoLogout";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];


const AppLogout = ({ children }) => {
    const [showAutoLogOff, SetShowAutoLogOff] = useState(false);
    const { instance } = useMsal();
    let counter = 30;
    const logoutCounter = 3000000; //milliseconds
    let timer;

    const { mutate: clearUserData } = useMutate({ endpoint: routes.clearUserData });

    //const {idleTimer} = useIdleTimeout({ onIdle: logoutAction, idleTime: 100 })

    const idleTimer = useIdleTimer({
        timeout: logoutCounter,
        //promptTimeout: logoutCounter / 2,
        //onPrompt: logoutAction,
        onIdle: logoutAction,
        debounce: 500
    })

    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    const handleTimer = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            logoutAction();
        }, logoutCounter);
    };

    function logoutAction(){
        SetShowAutoLogOff(true);
    };

    function handleOkToLogOff() {
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                });
            });
        }
        clearUserData({ body: JSON.stringify({}) });
        localStorage.clear();
        instance.logoutRedirect();
    }

    function handleCancel() {
        SetShowAutoLogOff(false);
        idleTimer.reset();
        //clearInterval(timer2);
        //SetCounter(10);
    }

    return (

        <Modal
            title="Session Timeout"
            open={showAutoLogOff}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        handleOkToLogOff();
                    }}
                >
                    Sign out
                </Button>,
                <Button key="submit"
                    type="primary"
                    onClick={() => {
                        handleCancel();
                    }}>
                    Stay
                </Button>
            ]}
        >
            <CountdownTimer initialSeconds={counter} onTimerUp={handleOkToLogOff}></CountdownTimer>
        </Modal>
    );
};

export default AppLogout;