import React from "react";
import { Dropdown, Switch } from "antd";

import { SettingOutlined } from "@ant-design/icons";
import { BDOButton, ButtonColours } from "../../components/Button";
import { BdoVoiceMessage } from "../BDOVoiceMessage";

import styles from "./SettingDropdown.module.css";
import { useDrawerContext } from "../../hooks/useDrawerContext";

export const SettingDropdown = () => {
  const { isBDOVoice, setIsBDOVoice } = useDrawerContext();
  const items = [
    {
      key: "1",
      type: "group",
      label: "BDO Voice",
      children: [
        {
          key: "1-1",
          label: (
            <>
              <div
                className={styles.bdoVoiceWwrapper}
                onClick={(e) => e?.stopPropagation()}
              >
                <div className={styles.switchWrapper}>
                  <Switch
                    preventDefault
                    size="small"
                    checked={isBDOVoice}
                    onChange={setIsBDOVoice}
                  />
                  <p className={styles.activeTextStyles}>Active</p>
                </div>
                <BdoVoiceMessage />
              </div>
            </>
          ),
        },
      ],
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      className={styles.accountDropdown}
      overlayClassName={styles.settingsDropdownOverlay}
    >
      <BDOButton
        Icon={<SettingOutlined />}
        variant={ButtonColours.ICON}
      ></BDOButton>
    </Dropdown>
  );
};
