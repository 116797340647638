import { Drawer, Collapse } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { UPDATES } from "../../constants";

import styles from "./NewUpdatesDrawer.module.css";

export const NewUpdatesDrawer = () => {
  const { showUpdatesDrawer, setShowUpdatesDrawer, isMobileView } =
    useDrawerContext();

  const closeDrawer = () => {
    setShowUpdatesDrawer(false);
  };
  const Panel = Collapse.Panel;

  return (
    <>
      <Drawer
        title="New updates"
        placement="right"
        onClose={closeDrawer}
        width={isMobileView ? "100%" : 300}
        open={showUpdatesDrawer}
        className={styles.sharefeedback}
      >
        <>
          <Collapse
            size="small"
            accordion
            defaultActiveKey={["1"]}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
          >
            {UPDATES.map(({ version, details }, index) => (
              <Panel
                className={styles.updatesPanel}
                header={
                  <span>
                    {index !== 0 ? version : <strong>{version}</strong>}
                  </span>
                }
                key={index + 1}
              >
                <p>{details}</p>
              </Panel>
            ))}
          </Collapse>
        </>
      </Drawer>
    </>
  );
};
