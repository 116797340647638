export function WrapAroundAgreement() {
  return (
    <div className="flex-col gap16 terms-modal">
      <h1 className="terms-header">Personas - Terms and conditions</h1>
      <div className="terms-box flex-col gap8">
        <p className="terms-header">
          By clicking <strong>I agree</strong>, I am confirming the following
          statements:
        </p>
        <ul className="terms">
          <li>
            I have read and understand the firm's{" "}
            <a
              className="policyLinks"
              href="https://insite.bdo.co.uk/sites/Risk/Documents/Acceptable%20Use%20Policy.pdf#search=AI%20policy"
              target="_blank"
            >
              Acceptable Use Policy
            </a>
            ,{" "}
            <a
              className="policyLinks"
              href="https://insite.bdo.co.uk/sites/Risk/Documents/Information%20Security%20Policy.pdf"
              target="_blank"
            >
              {" "}
              Information Security Policy
            </a>{" "}
            and have reviewed{" "}
            <a
              className="policyLinks"
              href="https://insite.bdo.co.uk/sites/IT/aboutit/innovationdigital/ai/Pages/Our-guidance-on-the-use-of-AI.aspx"
              target="_blank"
            >
              {" "}
              Guidance on the use of AI
            </a>{" "}
            for my stream specific policies and procedures.
          </li>
          <li>
            I understand that, as with all Generative AI applications, the
            generated output may contain inaccuracies. I am responsible for
            reviewing and checking the output, as well as ensuring that my work
            meets the firm’s quality standards and regulatory requirements.
          </li>
          <li>
            I understand that Personas uses similar technology to ChatGPT, but
            does not share my input data or use it further training.
          </li>
          <li>
            I note that Personas does not have access to live data sources
            either within BDO or externally, as information is derived from
            pre-trained sources, making it less suitable for conducting
            up-to-date research. The GPT model and date for it’s training data
            will be published under the ‘New updates’ tab for reference.
          </li>
          <li>
            Personas character limits are determined by the size of the input
            prompt, chosen Persona, and expected output.
            <br /> As these increase, they will also be published under the ‘New
            updates’ tab.
          </li>
          <li>
            I understand that this product may change or be unavailable at short
            notice, and that all data inputs or uploads are permitted to be used
            by this AI product to generate outputs for BDO.
          </li>
        </ul>
      </div>
    </div>
  );
}
