import { useEffect, useState } from "react";
import { TYPING_ANIMATION_SPEED } from "../../constants";
import { useDrawerContext } from "../../hooks/useDrawerContext";

export const TypingAnimation = ({ message, scrollToBottom }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const { setIsTypewriting, shouldAutoScroll } = useDrawerContext();
  useEffect(() => {
    if (index < message.length) {
      setIsTypewriting(true);
      const timeout = setTimeout(() => {
        setDisplayedText(displayedText + message[index]);
        setIndex(index + 1);
        if (shouldAutoScroll) {
          scrollToBottom?.();
        }
      }, TYPING_ANIMATION_SPEED);
      return () => clearTimeout(timeout);
    } else {
      setIsTypewriting(false);
    }
  }, [index, message, displayedText]);

  return displayedText;
};
