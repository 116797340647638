import { useState } from "react";
import { Drawer, Button } from "antd";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { routes } from "../../config/apiRoutes";
import { useMutate } from "../../hooks/useMutate";
import { notification } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

import styles from "./ShareFeedbackDrawer.module.css";
import classNames from "classnames";

export const ShareFeedbackDrawer = () => {
  const [api, contextHolder] = notification.useNotification();
  const { showFeedbackDrawer, setShowFeedbackDrawer, isMobileView } =
    useDrawerContext();
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const closeDrawer = () => {
    setShowFeedbackDrawer(false);
    setFeedback("");
  };

  const openFeedbackNotification = () => {
    api.open({
      placement: "bottomRight",
      message: "Feedback send successfully!",
      description: "Thank you for providing us with valuable feedback.",
      icon: <CheckCircleFilled style={{ color: "#198754" }} />,
    });
  };
  const { mutate: sendEmailFeedback } = useMutate({
    endpoint: routes.emailFeedback,
    onSuccess: () => {
      openFeedbackNotification();
      closeDrawer();
      setIsloading(false);
    },
    onError: () => {
      setIsloading(false);
    },
  });

  async function handleSendEmailFeedback() {
    const body = {
      subject: "Personas Feedback",
      plainTextContent: feedback,
      htmlContent: `<p> ${feedback}</p>`,
    };
    setIsloading(true);
    await sendEmailFeedback({ body: JSON.stringify(body) });
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="Share feedback"
        placement="right"
        onClose={closeDrawer}
        width={isMobileView ? "100%" : 300}
        open={showFeedbackDrawer}
        className={styles.sharefeedback}
      >
        <>
          <p className={styles.infoLabel}>
            Tell us about your experience using Personas
          </p>
          <textarea
            placeholder="Start typing your feedback here..."
            className={classNames(
              "noResize feedbackInput mb-4",
              styles.sharefeedbackTextAreaInputStyles
            )}
            value={feedback}
            id="feedback"
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
          <div className="flex-row" style={{ justifyContent: "flex-end" }}>
            <Button
              shape="square"
              type="primary"
              onClick={handleSendEmailFeedback}
              loading={isLoading}
              disabled={!feedback}
              className={styles.submitButton}
            >
              Submit
            </Button>
          </div>
        </>
      </Drawer>
    </>
  );
};
