import { Input, Upload } from "antd";

import {
  PaperClipOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import styles from "./ChatInputTextArea.module.css";
import { UploadFilePopover } from "../UploadFilePopover";
import { UploadedFilesViewTags } from "../UploadedFilesViewTags";
import { useDrawerContext } from "../../hooks/useDrawerContext";
const { TextArea } = Input;

export const ChatInputTextArea = ({
  input,
  setInput,
  executeGPTRequest,
  isLoading,
  isSendDisabled,
  callGPTAPI,
  uploadProps,
  showFileUploadButton,
  isFileUploadLoading,
  uploadFileList,
  handleFileRemoveChange,
}) => {
  const { isTypewriting } = useDrawerContext();
  return (
    <>
      <div className={styles.tagItemsWrapper}>
        <UploadedFilesViewTags
          files={uploadFileList}
          handleFileRemoveChange={handleFileRemoveChange}
        />
      </div>
      <div className={styles.TextAreaIconsWrapper}>
        <div className={styles.flexWrapperContainer}>
          {isFileUploadLoading ? (
            <div className={styles.loadingIconStyles}>
              <LoadingOutlined />
            </div>
          ) : (
            <UploadFilePopover
              uploadProps={uploadProps}
              showFileUploadButton={showFileUploadButton || !isTypewriting}
            >
              <Upload
                {...uploadProps}
                disabled={!showFileUploadButton}
                className={styles.paperClipStyles}
              >
                <PaperClipOutlined
                  className={styles.paperClipIcon}
                  style={{
                    cursor:
                      !showFileUploadButton || isTypewriting
                        ? "not-allowed"
                        : "pointer",
                    color:
                      !showFileUploadButton || isTypewriting
                        ? "var(--bdo-charcoal-light)"
                        : "var(--sub-text)",
                  }}
                />
              </Upload>
            </UploadFilePopover>
          )}
          <TextArea
            value={input}
            className={styles.textAreaInputStyles}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            id="transcript"
            autoSize={{ minRows: 1, maxRows: 6 }}
            placeholder="Enter your prompt here.."
            onKeyDown={(e) => {
              if (!isSendDisabled && !isLoading) {
                executeGPTRequest(e);
              }
            }}
          />

          {isLoading ? (
            <div className={styles.loadingIconStyles}>
              <LoadingOutlined />
            </div>
          ) : (
            <SendOutlined
              disabled={isSendDisabled}
              onClick={() => {
                if (!isSendDisabled) callGPTAPI();
              }}
              className={styles.sendIconStyles}
              style={{
                color: isSendDisabled
                  ? "var(--bdo-charcoal-light)"
                  : "var(--bdo-primary)",
                cursor: isSendDisabled ? "not-allowed" : "pointer",
              }}
            />
          )}
        </div>
      </div>
      <p className={styles.infoText}>
        Personas uses AI. You are responsible for validating the output.
      </p>
    </>
  );
};
