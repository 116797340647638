import React from "react";
import { Dropdown } from "antd";
import { useMsal } from "@azure/msal-react";
import { UserOutlined } from "@ant-design/icons";

import { useMutate } from "../../hooks/useMutate";
import { routes } from "../../config/apiRoutes";
import { BDOButton, ButtonColours } from "../../components/Button";

import styles from "./AccountDropdown.module.css";
import { ACCOUNT_DROPDOWN_ITEMS } from "../../constants";
import { useDrawerContext } from "../../hooks/useDrawerContext";

export const AccountDropdown = () => {
  const { setShowFeedbackDrawer, setShowUpdatesDrawer } = useDrawerContext();

  const { mutate: clearUserData } = useMutate({
    endpoint: routes.clearUserData,
  });
  const { instance } = useMsal();

  const logOut = () => {
    clearUserData({ body: JSON.stringify({}) });
    localStorage.clear();
    instance.logoutRedirect();
  };

  const handleShareFeedback = () => {
    setShowFeedbackDrawer(true);
  };

  const handleViewUpdates = () => {
    setShowUpdatesDrawer(true);
  };
  return (
    <Dropdown
      menu={{
        items: ACCOUNT_DROPDOWN_ITEMS(
          logOut,
          handleShareFeedback,
          handleViewUpdates
        ),
      }}
      className={styles.accountDropdown}
      overlayClassName={styles.accountDropdownOverlay}
    >
      <BDOButton
        Icon={<UserOutlined />}
        variant={ButtonColours.ICON}
      ></BDOButton>
    </Dropdown>
  );
};
