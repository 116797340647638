import {
  NotificationOutlined,
  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";

export const MOBILE_VIEW_PIXELS = 430;
export const TYPING_ANIMATION_SPEED = 20;
export const TABLET_VIEW_PIXELS = 768;
export const SMALL_TABLET_VIEW_PIXELS = 640;
export const EVIDENCE_WINDOW_VIEW_PIXELS = 1080;
export const MODEL_OPTIONS = [
  { value: "GPT4TURBO", label: "GPT-4 Turbo" },
  { value: "GPT4", label: "GPT-4" },
  { value: "GPT35", label: "GPT-3.5" },
];

export const ACCOUNT_DROPDOWN_ITEMS = (
  logOut,
  handleShareFeedback,
  handleViewUpdates
) => [
    {
      key: "1",
      type: "group",
      label: "Account",
      children: [
        {
          key: "1-1",
          label: "New updates",
          icon: <NotificationOutlined />,
          onClick: handleViewUpdates,
        },
        {
          key: "1-2",
          label: "Share feedback",
          icon: <MailOutlined />,
          onClick: handleShareFeedback,
        },

        {
          key: "1-3",
          label: "Sign out",
          icon: <UploadOutlined style={{ transform: "rotate(90deg)" }} />,
          onClick: logOut,
        },
      ],
    },
  ];

export const UPDATES = [
  {
    version: "Version 1.0.1 (Latest)",
    details: (
      <div>
        <ol type="1">
          <li>
            Application infrastructure improvements for scale, speed and efficiency.
          </li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 1.0",
    details: (
      <div>
        <ol type="1">
          <li>
            Fundamental Personas application rewritten for scale, speed, and
            efficiency.
          </li>
          <li>
            Implemented and defaulted to the latest OpenAI GPT-4o (Omni) model,
            trained on data up until October 2023.
          </li>
          <li>
            New look and feel based on feedback, incorporating exciting new Test
            branding.
          </li>
          <li>
            AI-generated output will now appear in 'real-time' as it's created
            via a refreshed conversational user interface.
          </li>
          <li>
            Personas is now in 'Preview' in alignment with Microsoft's approach.
            While some features aren't complete and there are some disclaimers,
            users can get early access and provide feedback.
          </li>
          <li>Personas is now mobile-friendly.</li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.9",
    details: (
      <div>
        <ol type="1">
          <li>Minor bug fixes and infrastructure improvements.</li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.8",
    details: (
      <div>
        <ol type="1">
          <li>
            Implemented and defaulted the latest GPT 4.0 Turbo model which is
            trained on data up until March 2023. This allows for more up to date
            and better quality outputs, significantly larger inputs, and at
            lower cost.
          </li>
          <li>
            Removed the waitlist functionality which will allow any BDO user to
            access Personas.
          </li>
          <li>
            Personas is now a Beta product, which means it is fully supported by
            IT. For any issues or queries, please raise a service now ticket{" "}
            <a href="https://insite.bdo.co.uk/sites/knowledge/redirects/Pages/go.aspx?title=itsupporthub">
              here
            </a>{" "}
          </li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.7",
    details: (
      <div>
        <ol type="1">
          <li>
            Implemented and defaulted a new version of GPT 4.0 model which allow
            for larger inputs and better outputs.
          </li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.6",
    details: (
      <div>
        <ol type="1">
          <li>
            Implemented a new version of GPT 3.5 which allows a larger input.
          </li>
          <li>Optimised Personas to handle more users at the same time.</li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.5",
    details: (
      <div>
        <ol type="1">
          <li>
            "Chat with your file/s" functionality has been upgraded. You can now
            upload the following file types:
            <ul>
              <li>PDF</li>
              <li>DOCX</li>
              <li>XLSX</li>
              <li>TXT</li>
            </ul>
          </li>
          <li>
            You can now choose either the default GPT-3.5 Turbo or GPT-4 models
            when submitting queries.
          </li>
          <li>
            Mechanism to automatically logout inactive users after 50 minutes.
          </li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.4",
    details: (
      <div>
        <ol type="1">
          <li>
            New UI
            <ul>
              <li>Name change from 'Personas GPT' to 'Personas'</li>
              <li>
                Persona Selection & BDO Voice on right side panel (collapsible)
              </li>
              <li>'New Chat' on left side panel (collapsible)</li>
              <li>BDO Voice text reduced to information tooltip</li>
              <li>Disclaimer moved from top panel to above input</li>
              <li>Labels changed from uppercase to lowercase</li>
            </ul>
            <li>
              "Chat with your file/s" functionality added. You can now upload up
              to 3 documents (<strong>only PDF</strong>) and ask questions
              against them.
            </li>
          </li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.3",
    details: (
      <div>
        <ol type="1">
          <li>
            Personas application has now switched to a conversational chat
            interface, rather than an input/output mechanism to improve
            usability.
          </li>
          <li>
            You can now save your own custom personas to your profile, you can
            then find these at the bottom of the persona selection list under
            'Your persona'.
          </li>
          <li>
            Your colleagues will now be able to request access to Personas
            directly and will be added to a waiting list. Access will be granted
            dependent on business case and capacity.
          </li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.2",
    details: (
      <div>
        <ol type="1">
          <li>Simple Input/Output interface.</li>
          <li>Implemented word count and limits for the input/output.</li>
        </ol>
      </div>
    ),
  },
  {
    version: "Version 0.1",
    details: (
      <div>
        <ol type="1">
          <li>
            First version of the Personas Experiment - imlementing OpenAI API in
            order to test its potentials with the initial Personas idea.
          </li>
        </ol>
      </div>
    ),
  },
];
