import classNames from "classnames";

export const CloseIcon = ({ className, onClick }) => {
  return (
    <span className={classNames("anticon", className)} onClick={onClick}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Close">
          <path
            id="Vector"
            d="M16.2971 6.55207L15.4473 5.70223C15.3717 5.62669 15.2979 5.62501 15.2257 5.69719L10.9759 9.94702L6.75535 5.72648C6.67981 5.65094 6.60594 5.64926 6.53376 5.72145L5.72169 6.53352C5.64951 6.6057 5.65119 6.67956 5.72673 6.7551L9.94726 10.9756L5.69744 15.2255C5.62526 15.2976 5.62693 15.3715 5.70247 15.447L6.55231 16.2969C6.62785 16.3724 6.70172 16.3741 6.7739 16.3019L11.0237 12.0521L15.2449 16.2733C15.3205 16.3488 15.3943 16.3505 15.4665 16.2783L16.2786 15.4663C16.3508 15.3941 16.3491 15.3202 16.2735 15.2447L12.0523 11.0235L16.3022 6.77366C16.3743 6.70147 16.3727 6.62761 16.2971 6.55207Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </span>
  );
};
