import React, { useState, createContext, useLayoutEffect } from "react";
import {
  MOBILE_VIEW_PIXELS,
  SMALL_TABLET_VIEW_PIXELS,
  TABLET_VIEW_PIXELS,
  EVIDENCE_WINDOW_VIEW_PIXELS,
} from "../constants";

export const DrawerContext = createContext({});

export function DrawerContextProvider({ children }) {
  const defaultGptModel = process.env.REACT_APP_DEFAULT_GPT_MODEL;
  const [selectedModel, setSelectedModel] = useState(defaultGptModel);
  const [rightsideCollapseStatus, setRightsideCollapseStatus] = useState(false);
  const [showFeedbackDrawer, setShowFeedbackDrawer] = useState(false);
  const [showUpdatesDrawer, setShowUpdatesDrawer] = useState(false);
  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);
  const [showEvidencesDrawer, setShowEvidenceDrawer] = useState(false);
  const [showEvidenceInSidebar, setShowEvidenceInSidebar] = useState(
    window.innerWidth > EVIDENCE_WINDOW_VIEW_PIXELS
  );
  const [isBDOVoice, setIsBDOVoice] = useState(true);
  const [isTypewriting, setIsTypewriting] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [pdfEvidenceUrl, setPdfEvidenceUrl] = useState({});

  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= MOBILE_VIEW_PIXELS
  );
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > MOBILE_VIEW_PIXELS &&
      window.innerWidth <= TABLET_VIEW_PIXELS
  );

  const [isSmallTabletView, setIsSmallTabletView] = useState(
    window.innerWidth < SMALL_TABLET_VIEW_PIXELS
  );

  const handleSetPdfUrl = (data, showEvidence) => {
    setPdfEvidenceUrl(data);
    setShowEvidenceDrawer(showEvidence);
  };
  useLayoutEffect(() => {
    const handleResize = () => {
      setShowFeedbackDrawer(false);
      setShowUpdatesDrawer(false);
      setShowSettingsDrawer(false);
      if (window.innerWidth > EVIDENCE_WINDOW_VIEW_PIXELS) {
        setShowEvidenceInSidebar(true);
      } else {
        setShowEvidenceInSidebar(false);
      }
      if (window.innerWidth < SMALL_TABLET_VIEW_PIXELS) {
        setIsSmallTabletView(true);
        setRightsideCollapseStatus(true);
      } else {
        setIsSmallTabletView(false);
        setRightsideCollapseStatus(false);
      }
      if (
        window.innerWidth > MOBILE_VIEW_PIXELS &&
        window.innerWidth <= TABLET_VIEW_PIXELS
      ) {
        setIsTabletView(true);
        setIsMobileView(false);
        setRightsideCollapseStatus(true);
      } else if (window.innerWidth <= MOBILE_VIEW_PIXELS) {
        setIsMobileView(true);
        setIsTabletView(false);
        setRightsideCollapseStatus(true);
      } else {
        setIsMobileView(false);
        setIsTabletView(false);
        setRightsideCollapseStatus(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <DrawerContext.Provider
      value={{
        rightsideCollapseStatus,
        setRightsideCollapseStatus,
        showFeedbackDrawer,
        setShowFeedbackDrawer,
        showUpdatesDrawer,
        setShowUpdatesDrawer,
        isBDOVoice,
        setIsBDOVoice,
        selectedModel,
        setSelectedModel,
        isMobileView,
        isSmallTabletView,
        isTabletView,
        showSettingsDrawer,
        setShowSettingsDrawer,
        isTypewriting,
        setIsTypewriting,
        shouldAutoScroll,
        setShouldAutoScroll,
        showEvidencesDrawer,
        setShowEvidenceDrawer,
        pdfEvidenceUrl,
        setPdfEvidenceUrl,
        handleSetPdfUrl,
        showEvidenceInSidebar,
        setShowEvidenceInSidebar,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}
